import InvestorLayout from "../utils/InvestorLayout"

const AGM = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Annual/ Extra-ordinary General Meeting" links={landingPageData.AGM}/>
    </div>
  )
}

export default AGM
