import Links from './Links'

const InvestorLayout = (data) => {
  console.log(data);
  return (
    <div id='InvestorLayout'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='container'>
          <div className='section-title text-center'>
            <h1>{data.title}</h1>
          </div>
          <div >
            {data.links && data.links.map((listLink, i) => (
              <Links  data={listLink}/>
            ))}

          </div>

        </div>
    </div>
  )
}

export default InvestorLayout;
