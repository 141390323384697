import InvestorLayout from "../utils/InvestorLayout"

const AnnualReturn = ({landingPageData}) => {
  return (
    <div>
      <InvestorLayout title="Annual Return" links={landingPageData.AnnualReturn}/>
    </div>
  )
}

export default AnnualReturn
