import InvestorLayout from "../utils/InvestorLayout"

const InvestorPresentation = ({landingPageData}) => {
  return (
    <div>
      <InvestorLayout title="Investor Presentation" links={landingPageData.InvestorPresentation}/>
    </div>
  )
}

export default InvestorPresentation
