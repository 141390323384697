import InvestorLayout from "../utils/InvestorLayout"

const StockExchange = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Stock Exchange Intimition" links={landingPageData.StockExchange}/>
    </div>
  )
}

export default StockExchange
