import InvestorLayout from "../utils/InvestorLayout"

const ShareholdingPattern = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Share Holding Pattern" links={landingPageData.ShareHolding} />
    </div>
  )
}

export default ShareholdingPattern
