
const CorporateInformation = ({landingPageData}) => {
  return (
    <div id='corporateinformation'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='container'>
          <div className='section-title text-center'>
            <h1>Corporate Information</h1>
          </div>
      <table class="container table table-bordered">
        <tbody>
          <tr>
            <td>Name</td>
            <td>Jalan Transolutions (India) Limited</td>

          </tr>
          <tr>
            <td>Corporate Information Number</td>
            <td>L63090DL2003PLC119773</td>

          </tr>
          <tr>
            <td >Date of Incorporation</td>
            <td>April 07, 2003</td>
          </tr>
          <tr>
            <td >Date of Listing	</td>
            <td>May 30, 2017</td>
          </tr>
          <tr>
            <td >Registered Office</td>
            <td>206, Ajnara Bhawan, D-Block Market,<br/>
                Vivek Vihar, Delhi-110095
            </td>
          </tr>
          <tr>
            <td >Corporate Office Office</td>
            <td>2F-CS-38, Second Floor, Ansal Plaza,<br/>
                Vaishali, Ghaziabad, U.P-201010
            </td>
          </tr>
          <tr>
            <td >Managing Director</td>
            <td>Mr. Manish Jalan</td>
          </tr>
          <tr>
            <td >Company Secretary cum Compliance Officer</td>
            <td>CS Shruti Goel</td>
          </tr>
          <tr>
            <td >Depository</td>
            <td>National Securities Depository Limited;<br/>
                Central Depository Services (India) Limited
            </td>
          </tr>
          <tr>
            <td >ISIN	</td>
            <td>INE349XO1015</td>
          </tr>
          <tr>
            <td >Registrar & Transfer Agent</td>
            <td>
              NIVIS CORPSERVE LLP.<br/>
              Corporate Office Address:<br/>
              STA Office-03, Shankar Vihar,<br/>
              2nd Floor, Vikas Marg , Delhi -110092<br/>
              Registered Address: F-111, Community Centre<br/>
              Preet Vihar Delhi -110092<br/>
              Phone No.: <a href="tel:011-4520105">011-4520105</a>,<br/>
              Email: <a href="mailto:info@nivis.co.in,gm@nivis.co.in">info@nivis.co.in,gm@nivis.co.in</a><br/><br/>
              SEBI Registration No: INR000004264
              </td>
          </tr>
        </tbody>
      </table>
      </div>
    </div>
  )
}

export default CorporateInformation
