import InvestorLayout from "../utils/InvestorLayout"

const BoardMeetings = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Board Meetings" links={landingPageData.BoardMeetings} />
    </div>
  )
}

export default BoardMeetings
