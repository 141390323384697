const InvestorGrievances = ({landingPageData}) => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='container'>

        <div className='section-title text-center'>
          <h1>Investor Gievances Cell</h1>
        </div>

         <div>
            <h3>PROMOTER & MANAGING DIRECTOR</h3>

            <p><strong>MANISH JALAN</strong></p>
            <p>
              JALAN TRANSOLUTIONS (INDIA) LIMITED<br/>
              2F-CS-38, Second Floor, Ansal Plaza,<br/>
              Vaishali, Ghaziabad, U.P-201010<br/>
              Phone No.: <a href="tel:+-0120-4257719">+91-0120-4257719</a> <br/>
              Email: <a href="mailto:manish@jalantransolutions.com">manish@jalantransolutions.com</a> <br/><br/>
           </p>

         </div>
         <div>
            <h3>COMPANY SECRETARY CUM COMPLIANCE OFFICER</h3>

            <p><strong>SHRUTI GOEL</strong></p>
            <p>
              JALAN TRANSOLUTIONS (INDIA) LIMITED<br/>
              2F-CS-38, Second Floor, Ansal Plaza,<br/>
              Vaishali, Ghaziabad, U.P-201010<br/>
              Phone No.: <a href="tel:+91-0120-4257719">+919711005602</a><br/>
              Email: <a href="mailto:compsec@jalantransolutions.com">compsec@jalantransolutions.com</a><br/><br/>
           </p>

         </div>
         <div>
            <h3>REGISTRAR & TRANSFER AGENT</h3>

            <p><strong>NIVIS CORPSERVE LLP.</strong></p>
            <p>
              STA Office-03, Shankar Vihar,<br/>
              2nd Floor, Vikas Marg , Delhi -110092<br/>
              Registered Address: F-111, Community Centre<br/>
              Preet Vihar Delhi -110092<br/>
              Phone No.: <a href="tel:011-4520105">011-4520105</a>,<br/>
              Email: <a href="mailto:info@nivis.co.in,gm@nivis.co.in">info@nivis.co.in,gm@nivis.co.in</a><br/><br/>
              SEBI Registration No: INR000004264
           </p>
         </div>

      </div>
    </div>
  )
}

export default InvestorGrievances
