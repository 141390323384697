import InvestorLayout from "../utils/InvestorLayout"

const Ipo = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="IPO" links={landingPageData.IPO}/>
    </div>
  )
}

export default Ipo
