import React from 'react'

const Links = ({data}) => {
  console.log(data);
  return (
    <div id='Links'>
      {data && <a href = {data.link} target="_blank" rel="noreferrer"><p>{data.content}</p></a>}
    </div>
  )
}

export default Links
