import InvestorLayout from "../utils/InvestorLayout"

const AnnualReport = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Annual Report" links={landingPageData.AnnualReport} />
    </div>
  )
}

export default AnnualReport
