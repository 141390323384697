const KmpAuthorised = ({heading}) => {
  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='container'>
        <div className='section-title text-center'>
          <h1>{heading}</h1>
        </div>
        <div>
          <p><strong>MANISH JALAN</strong></p>
          <p>Managing Director</p>
          <p>
            JALAN TRANSOLUTIONS (INDIA) LIMITED<br/>
            2F-CS-38, Second Floor, Ansal Plaza,<br/>
            Vaishali, Ghaziabad, U.P-201010<br/>
            Phone No.: <a href="tel:+-0120-4257719">+91-0120-4257719</a> <br/>
            Email: <a href="mailto:manish@jalantransolutions.com">manish@jalantransolutions.com</a> <br/><br/>
          </p>
        </div>
        <div>
          <p><strong>SHRUTI GOEL</strong></p>
          <p>Company Secretary cum Compliance Officer</p>
          <p>
            JALAN TRANSOLUTIONS (INDIA) LIMITED<br/>
            2F-CS-38, Second Floor, Ansal Plaza,<br/>
            Vaishali, Ghaziabad, U.P-201010<br/>
            Phone No.: <a href="tel:+91-0120-4257719">+919711005602</a><br/>
            Email: <a href="mailto:compsec@jalantransolutions.com">compsec@jalantransolutions.com</a><br/><br/>
          </p>
        </div>
      </div>
    </div>
  )
}

export default KmpAuthorised
