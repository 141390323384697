import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import JsonData from "./data/data.json";
import Home from './components/pages/Home'
import InvestorRelations from './components/pages/InvestorRelations'
import CorporateInformation from './components/pages/CorporateInformation'
import FinancialResults from './components/pages/FinancialResults'
import ShareholdingPattern from './components/pages/ShareholdingPattern'
import AnnualReport from './components/pages/AnnualReport'
import BoardMeetings from './components/pages/BoardMeetings'
import AGM from './components/pages/AGM'
import Policies from './components/pages/Policies'
import StockExchange from './components/pages/StockExchange'
import InvestorGrievances from './components/pages/InvestorGrievances'
import Ipo from './components/pages/Ipo'
import KmpAuthorised from "./components/pages/KmpAuthorised";
import "./App.css";
import Navigation from "./components/utils/Navigation";
import Contact from "./components/utils/Contact";
import ScrollToTop from './components/utils/ScrollToTop'
import BoardOfDirectors from "./components/pages/BoardOfDirectors";
import InvestorPresentation from "./components/pages/InvestorPresentation";
import AnnualReturn from "./components/pages/AnnualReturn";

const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <Router>
        <Navigation/>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element = {<Home landingPageData={landingPageData} />} />

          <Route path="/investorRelations" element = {<InvestorRelations landingPageData={landingPageData} />} />

          <Route path="/investorRelations/corporate-information" element = {<CorporateInformation landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/financial-results" element = {<FinancialResults landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/shareholding-pattern" element = {<ShareholdingPattern landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/annual-report" element = {<AnnualReport landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/board-meetings" element = {<BoardMeetings landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/agm" element = {<AGM landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/policies" element = {<Policies landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/stock-exchange-intimition" element = {<StockExchange landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/investor-grievances-cell" element = {<InvestorGrievances landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/ipo" element = {<Ipo landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/annual-return" element = {<AnnualReturn landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/board-of-directors" element = {<BoardOfDirectors landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/investor-presentation" element = {<InvestorPresentation landingPageData={landingPageData}/>} />

          <Route path="/investorRelations/kmp-authorised" element = {<KmpAuthorised heading={'KMP Authorised to Determine Materiality of Event'}/>} />

          <Route path="/investorRelations/official-details-investor-grievances" element = {<KmpAuthorised heading={'Details of Official: Investor Grievances'}/>} />

        </Routes>
        <Contact data={landingPageData.Contact} />
    </Router>
  );
};

export default App;
