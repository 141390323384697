
const BoardOfDirectors = () => {
  return (
    <div id='corporateinformation'>
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className='container'>
        <div className='section-title text-center'>
          <h1>Composition of committees of Board of directors</h1>
        </div>
        <table className="container table table-bordered">
          <tbody>
          <tr>
            <td>Audit Committee</td>
            <td>
              Mr. Sanjay Sharma :  Chairman <br/>
              Mr. Anil Kumar :  Member <br/>
              Mr. Manish Jalan :  Member <br/>
            </td>

          </tr>
          <tr>
            <td>Nomination and Remuneration Committee</td>
            <td>
              Mr. Anil Kumar :  Chairman <br/>
              Mr. Sanjay Sharma :  Member <br/>
              Mrs. Ritu Jalan :  Member <br/>
            </td>

          </tr>
          <tr>
            <td >Stakeholder Relationship Committee</td>
            <td>
              Mrs. Ritu Jalan :  Chairman
              Mr. Manish Jalan :  Member
              Mr. Anil Kumar :  Member
            </td>
          </tr>
          <tr>
            <td >Date of Listing	</td>
            <td>May 30, 2017</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BoardOfDirectors
