import InvestorLayout from "../utils/InvestorLayout"

const FinancialResults = ({landingPageData}) => {
  return (
      <div id='FinancialResults'>
        <div id='InvestorLayout'>
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className='container'>
          <div className='section-title text-center'>
            <h1>Financial Results</h1>
          </div>
          <div >
            {landingPageData.FinancialResults && landingPageData.FinancialResults.map((result, i) => (
              <>
              <InvestorLayout title={result.content} links={result.ilink} />
              </>
            ))}

          </div>

        </div>
    </div>
    </div>
  )
}

export default FinancialResults
