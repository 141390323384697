import InvestorLayout from "../utils/InvestorLayout"

const Policies = ({landingPageData}) => {
  return (
    <div>
        <InvestorLayout title="Policies & Certificates" links={landingPageData.Policies}/>
    </div>
  )
}

export default Policies
