const Team = (props) => {
  return (
    <div id='team' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Meet the Team</h2>
        </div>
        <div>
          <img src="img/team.jpg" className="img-responsive team team-img" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Team;