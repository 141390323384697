import  Header  from '../utils/Header';
import  About  from '../utils/About';
import  Services  from '../utils/Services';
// import  Testimonials  from '../utils/Testimonials';
import  Team  from '../utils/Team';


const Home = ({landingPageData}) => {
  return (
    <div>
      <Header data={landingPageData.Header} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      {/* <Testimonials data={landingPageData.Testimonials} /> */}
      <Team data={landingPageData.Team} />
    </div>
  )
}

export default Home;